import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { getErrorMessage, successStatus } from '../../common';
import Accordion from '../../components/Accordion';
import { SkillsChipsBlue } from '../../components/Chips';
import { Button } from '../../components/common/Button';
import OutlinedButton from '../../components/common/OutlinedButton';
import { AddBlueIcon } from '../../components/Icons/AddBlueIcon';
import { BookIcon } from '../../components/Icons/BookIcon';
import { CertificateIcon } from '../../components/Icons/CertificateIcon';
import EditBlueIcon from '../../components/Icons/EditBlueIcon';
import { ExperienceIcon } from '../../components/Icons/ExperienceIcon';
import { MediaIcon } from '../../components/Icons/MediaIcon';
import SkillsIcon from '../../components/Icons/SkillsIcon';
import InputBox from '../../components/InputBox';
import Modal from '../../components/Modal';

import {
  addCareerLinks,
  fetchCareerLinkslist,
  fetchCareerSkillslist,
  fetchCareersList,
  updateCareerSkills,
} from '../../services/signup';
import { validationSchemaTitle } from '../../validations';
import { CertificateContent } from './CertificateContent';
import { EducationContent } from './EducationContent';
import { ExperienceContent } from './ExperienceContent';
import { MediaContent } from './MediaContent';
import check from '../../assets/images/check.png';
import cross from '../../assets/images/cross.png';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCareerTitleDispatcher,
  updateCareerTitleDispatcher,
} from '../../redux/dispatchers/signupDispatcher';
import SpinningLoader from '../../components/common/SpinningLoader';
import { LIMITS, REGEX } from '../../constants/constants';
import LinkForm from './LinkForm';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { LinkData } from '../../components/common/Work/LinkData';
import LinksIcon from '../../components/Icons/LinksIcon';
import SkillForm from './SkillForm';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import { deleteWorkDispatcher } from '../../redux/dispatchers/infoDispatcher';
import Loader from '../../components/common/Loader';
import { globalTransparentLoadingPrivate } from '../../redux/slices/authSlice';

const { LINK_PATTERN } = REGEX;

export function CareerForm({
  getCareerList = () => {},
  id = null,
  data = {},
  fetchCareerDataById = () => {},
  origin = null, // This will be used for knowing if it is being called from my profile or elsewhere
}) {
  const ref = useRef();
  const dispatch = useDispatch();
  const [isSkillModalOpen, setIsSkillModalOpen] = useState(false);
  const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

  const [links, setLinks] = useState([]); // This is the local state which will get updated each time a link is added or edited
  const [linksFromAPI, setLinksFromAPI] = useState([]); // Setting the links from API
  const [deletedLinks, setDeletedLinks] = useState([]); // We need to pass {id: <DELETED LINK ID>} in the API to remove the links
  const [linkInInput, setLinkInInput] = useState({
    url: '',
    domain: '',
  });
  const [careerTitles, setCareerTitles] = useState([]);

  const [skills, setSkills] = useState([]); // This is the local state which will get updated each time a link is added or edited
  const [skillsFromAPI, setSkillsFromAPI] = useState([]); // Setting the skills from API

  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(id ? false : true);
  const [prevTitle, setPrevTitle] = useState(id ? data?.title : '');
  const [isLoading, setIsLoading] = useState({
    title: false,
    links: false,
    skills: false,
    delete: false,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const isGlobalTransparentLoadingPrivate = useSelector(
    (state) => state?.auth?.globalTransparentLoadingPrivate,
  );

  useEffect(() => {
    if (id) {
      setPrevTitle(data?.title);
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [id, data]);

  const openLinksModalHandler = () => {
    if (!id) {
      return;
    }
    setLinkInInput({ url: '', domain: '' });
    setDeletedLinks([]);
    setLinks(linksFromAPI || []);
    setIsLinksModalOpen(true);
  };

  // Function to handle key down event in the form
  const handleKeyDown = (e) => {
    // Check if the pressed key is 'Enter'
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
    }
  };

  const careerTitleHandler = async (notifyOthers = false) => {
    if (!isLoading?.title) {
      setIsLoading({ ...isLoading, title: true });

      if (!id) {
        // we will call this api in case only when there is no career id
        //  because of we are calling this function onblur
        let dataToSend = {
          title: title?.trim(),
          notifyOthers: typeof notifyOthers === 'boolean' ? notifyOthers : false,
        };
        const response = await dispatch(addCareerTitleDispatcher(dataToSend));
        const { status, data } = response;

        if (successStatus(status)) {
          fetchCareerDataById(data?.data?.id);
          setIsEdit(false);
          getCareerList();
        }
      } else {
        let dataToUpdate = {
          postData: {
            title: title,
          },
          id,
        };
        const response = await dispatch(updateCareerTitleDispatcher(dataToUpdate));
        const { status } = response;
        if (successStatus(status)) {
          getCareerList();
          setIsEdit(false);
          fetchCareerDataById(id);
        }
      }
      setIsLoading({ ...isLoading, title: false });
      setIsCreateModalOpen(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: prevTitle,
    },
    validationSchema: validationSchemaTitle,
    onSubmit: careerTitleHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isEdit) {
      formik?.resetForm();
    }
  }, [isEdit]);

  const {
    values: { title },
    touched: { title: tuc_title },
    errors: { title: err_title },
    handleSubmit,
  } = formik;

  const getLinksList = async () => {
    const response = await fetchCareerLinkslist(id);
    const { status, data } = response;
    if (successStatus(status)) {
      setLinks(data?.data || []);
      setLinksFromAPI(data?.data || []);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg, '');
      }
    }
  };

  const getSkillsList = async () => {
    const response = await fetchCareerSkillslist(id);
    const { status, data } = response;

    if (successStatus(status)) {
      let skills = data?.data?.map((skill) => skill?.skill);
      setSkills(skills);
      setSkillsFromAPI(skills);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg, '');
      }
    }
  };

  useEffect(() => {
    if (id) {
      getSkillsList();
      getLinksList();
    }
  }, [id]);

  const linksSubmitHandler = async () => {
    if (isLoading?.links) return;

    setIsLoading({ ...isLoading, links: true });
    dispatch(globalTransparentLoadingPrivate(true));

    let link = {
      url: linkInInput?.url?.trim(),
      domain: linkInInput?.domain?.trim(),
    };
    let allLinks = [...links, ...deletedLinks];

    // If there is anything typed in the input box and the plus button is not clicked, so we need to check
    // if there is some value in it and if it's valid
    if (link?.url || link?.domain) {
      if (!link?.url.startsWith('https://')) {
        link['url'] = `https://${link?.url}`;
      }

      if (!LINK_PATTERN.test(link?.url)) {
        ToastNotifyError('Invalid URL', '');
        setIsLoading({ ...isLoading, links: false });
        return false;
      } else if (!link?.domain) {
        ToastNotifyError('Domain is required', '');
        setIsLoading({ ...isLoading, links: false });
        return false;
      } else {
        allLinks = [link, ...allLinks];
      }
    }

    const response = await addCareerLinks({
      careerId: id,
      links: allLinks,
    });
    const { status } = response;
    if (successStatus(status)) {
      getLinksList();

      setIsLinksModalOpen(false);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg, 'location-failed');
      }
    }
    setIsLoading({ ...isLoading, links: false });
    dispatch(globalTransparentLoadingPrivate(false));
  };

  const skillsSubmitHandler = async () => {
    if (isLoading?.skills) return;

    setIsLoading({ ...isLoading, skills: true });
    dispatch(globalTransparentLoadingPrivate(true));

    const response = await updateCareerSkills(skills || [], id);
    const { status } = response;
    if (successStatus(status)) {
      getSkillsList();
      setIsSkillModalOpen(false);
    } else {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg, 'location-failed');
      }
    }
    setIsLoading({ ...isLoading, skills: false });
    dispatch(globalTransparentLoadingPrivate(false));
  };

  const deleteCareer = async () => {
    if (isLoading?.delete) return;

    setIsLoading({ ...isLoading, delete: true });
    dispatch(globalTransparentLoadingPrivate(true));

    const response = await dispatch(deleteWorkDispatcher({ id: id }));
    const { status, data } = response;
    if (!successStatus(status)) {
      const errormsg = getErrorMessage(data);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    } else {
      setIsDeleteModalOpen(false);
      formik?.resetForm();
      formik.setFieldValue('title', '');
      setLinkInInput('');
      setLinks([]);
      setSkills([]);
      setSkillsFromAPI([]);
      setLinksFromAPI([]);
      setDeletedLinks([]);
      await getCareerList();
      await fetchCareerDataById();

      if (origin === 'MY_PROFILE') {
        navigate(`${PATHS.PROFILE}/work`, { replace: true });
      }
    }
    setIsLoading({ ...isLoading, delete: false });
    dispatch(globalTransparentLoadingPrivate(false));
  };

  // New function to handle click on check image
  const handleCheckClick = async () => {
    const titleFromFormik = formik.values?.title?.trim().toLowerCase();
    const isTitleMatching = careerTitles.some(
      (career) => career.title?.trim().toLowerCase() === titleFromFormik,
    );
    if (!isTitleMatching) {
      const errors = await formik.validateForm();
      formik.setTouched({ title: true });
      if (!errors.title) {
        if (origin === 'MY_PROFILE' && !id) {
          setIsCreateModalOpen(true);
        } else {
          await careerTitleHandler(false);
        }
      }
    } else {
      ToastNotifyError('Career title with the same name already exists');
    }
  };

  const getAllCareer = async () => {
    let response = {};
    {
      response = await fetchCareersList();
    }
    const {
      status,
      data: { data },
    } = response;

    if (successStatus(status)) {
      setCareerTitles(data?.Careers);
    }
  };

  useState(() => {
    getAllCareer();
  }, []);

  return (
    <div
      className={`relative w-full ${
        isGlobalTransparentLoadingPrivate ? 'pointer-events-none' : ''
      }`}
    >
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <div className="md:flex gap-[5%] items-center mt-8">
          <div className={'form-title mb-2 md:mb-[22px]'}>Career Title</div>
          <div className={'grow des-title gap-4 md:justify-normal'}>
            <InputBox
              name="title"
              parentClassName="w-[245px] md:w-[400px]"
              placeholder="Enter Title"
              value={title}
              initialValue={title}
              onChange={(e) => {
                formik.setFieldValue('title', e.target.value);
              }}
              disabled={!isEdit}
              error={tuc_title && err_title}
              helperText={tuc_title && err_title}
              maxLength={LIMITS.MAX_CAREER_TITLE_LENGTH}
            />

            {isEdit ? (
              <div className="mb-[22px] flex">
                {!isLoading?.title ? (
                  <>
                    <button type="button">
                      <img
                        src={check}
                        alt="check"
                        className="ml-0 md:ml-[20px] cursor-pointer"
                        onClick={handleCheckClick}
                      />
                    </button>
                    <img
                      src={cross}
                      alt="cross"
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      onClick={() => {
                        setIsEdit(false);
                        formik.setFieldValue('title', prevTitle);
                      }}
                    />

                    <ConfirmationModal
                      title="Notify others"
                      isOpen={isCreateModalOpen}
                      onClose={() => setIsCreateModalOpen(false)}
                      secondaryButtonTitle="No"
                      secondaryButtonAction={() => careerTitleHandler(false)}
                      primaryButtonTitle="Yes"
                      primaryButtonAction={() => careerTitleHandler(true)}
                      isPrimaryButtonDisabled={isLoading?.title}
                    >
                      <div className="text-[18px] tx-greydark font-medium">
                        Do you want to notify your connections about your profile update?
                      </div>
                    </ConfirmationModal>
                  </>
                ) : (
                  <SpinningLoader marginLeft="ml-0 md:ml-[20px]" />
                )}
              </div>
            ) : (
              <span
                className="md:ml-[20px] mb-[22px] cursor-pointer"
                onClick={() => setIsEdit(true)}
              >
                <EditBlueIcon />
              </span>
            )}
          </div>
          {id ? (
            <div className={'mb-2 md:mb-[22px]'}>
              <Button
                showArrowIcon={false}
                label="Delete"
                isDelete
                onClick={() => setIsDeleteModalOpen(true)}
                isLoading={isLoading?.delete}
                isDisabled={isLoading?.delete}
                onlyShowLoaderWhenLoading={true}
                additionalClassNames="text-[14px]"
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <p className="font-normal text-greydark mb-5 mt-2">
          Your information will be grouped and displayed by career field. It helps people quickly
          identify your many talents.
        </p>
      </form>
      <div>
        <Accordion
          disabled={!id}
          parentClassName={!id ? 'cursor-not-allowed opacity-60' : ''}
          items={[
            {
              icon: <MediaIcon />,
              title: 'Media',
              content: <MediaContent mediaRef={ref} careerId={id} />,
            },

            {
              icon: <ExperienceIcon />,
              title: 'Experience',
              content: <ExperienceContent careerId={id} />,
            },
            {
              icon: <BookIcon />,
              title: 'Education',
              content: <EducationContent careerId={id} />,
            },

            {
              icon: <CertificateIcon />,
              title: 'Certifications',
              content: <CertificateContent careerId={id} />,
            },
          ]}
        />
        {linksFromAPI.length > 0 && (
          <div className="w-full text-left py-[17px] px-[24px] bg-white mb-[16px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="mr-4">
                  <LinksIcon />
                </span>
                <span className="form-title-blue">Links</span>
              </div>
              <span onClick={openLinksModalHandler} className="cursor-pointer">
                <EditBlueIcon />
              </span>
            </div>
            <div className="text-[12px] font-medium mt-2 mb-3">
              ({linksFromAPI.length}/5 Links Uploaded)
            </div>
            <LinkData
              openModalHandler={openLinksModalHandler}
              data={linksFromAPI}
              isEditable={true}
            />
          </div>
        )}

        {skillsFromAPI.length > 0 && (
          <div className="w-full text-left py-[17px] px-[24px] bg-white mb-[16px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="mr-4">
                  <SkillsIcon />
                </span>
                <span className="form-title-blue">Skills</span>
              </div>
              <span
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  setIsSkillModalOpen(true);
                }}
                className="cursor-pointer"
              >
                <EditBlueIcon />
              </span>
            </div>
            <div className="flex gap-[24px] grow-0 mt-6 flex-wrap">
              {skillsFromAPI?.map((name, idx) => (
                <SkillsChipsBlue label={name} key={idx} />
              ))}
            </div>
          </div>
        )}
        <div className="mt-[36px] flex justify-end md:justify-between flex-wrap">
          <div className="flex gap-4 flex-wrap">
            <div>
              <OutlinedButton
                disabled={!id}
                label="Add Links"
                Icon={<AddBlueIcon />}
                onClick={openLinksModalHandler}
              />
            </div>
            <div>
              <OutlinedButton
                label="Add Skills"
                disabled={!id}
                Icon={<AddBlueIcon />}
                onClick={() => {
                  if (!id) {
                    return;
                  }
                  setIsSkillModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title="Delete Career"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        primaryButtonTitle="Delete"
        primaryButtonAction={() => deleteCareer()}
        secondaryButtonTitle="Cancel"
        secondaryButtonAction={() => setIsDeleteModalOpen(false)}
        isPrimaryButtonDisabled={isLoading?.delete}
      >
        Are you sure you want to delete this Career?
      </ConfirmationModal>

      <Modal
        isTitle={true}
        title="Add Links"
        isOpen={isLinksModalOpen}
        onClose={() => setIsLinksModalOpen(false)}
        width="max-w-[472px]"
        padding={0}
        titleClassNames="pl-0"
      >
        <div className="px-6">
          <LinkForm
            links={links}
            setLinks={setLinks}
            linkInInput={linkInInput}
            setLinkInInput={setLinkInInput}
            isInputLinkOpen={true}
            updateDeletedLinks={setDeletedLinks}
          />

          <div className="grid justify-items-end pb-5">
            <Button
              disabled={isLoading?.links}
              label="Save"
              onClick={() => linksSubmitHandler()}
              showArrowIcon={false}
              isLoading={isLoading?.links}
              onlyShowLoaderWhenLoading={true}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isTitle={true}
        title="Add Skills"
        isOpen={isSkillModalOpen}
        onClose={() => setIsSkillModalOpen(false)}
        width="max-w-[472px]"
        padding={0}
        titleClassNames="pl-0"
      >
        <>
          <SkillForm skillsList={skills} updateSkillsList={setSkills} />

          <div className="bg-greymedium h-[1px] w-full" />
          <div className="grid justify-items-end pt-6 pb-5 px-6">
            <Button
              disabled={!title}
              label="Save"
              onClick={() => skillsSubmitHandler()}
              showArrowIcon={false}
              isLoading={isLoading?.skills}
              isDisabled={isLoading?.skills}
              onlyShowLoaderWhenLoading={true}
            />
          </div>
        </>
      </Modal>

      {isGlobalTransparentLoadingPrivate ? (
        <div className="fixed top-0 left-0 bg-[#b3b3b366] h-screen w-screen z-[510] pointer-events-none">
          <Loader />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
