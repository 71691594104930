import React, { useEffect, useRef, useState } from 'react';
import { UploadIcon } from '../../components/Icons/UploadIcon';
import OutlinedButton from '../../components/common/OutlinedButton';
import AutoComplete from '../../components/common/AutoComplete';
import {
  addUserInterest,
  deleteInterest,
  fetchAllStaticInterests,
  fetchFileUPloadAWS,
  fetchGenratePreSignedUrl,
  updateUserInterest,
} from '../../services/signup';
import { getErrorMessage, successStatus } from '../../common';
import {
  ToastNotifyError,
  ToastNotifyInfo,
  ToastNotifySuccess,
} from '../../components/Toast/ToastNotify';
import { useFormik } from 'formik'; // Import Formik
import { validationSchemaTitle } from '../../validations';
import { INTEREST_PHOTO_TYPES, INTEREST_VIDEO_TYPES, LIMITS } from '../../constants/constants';
import compressImage from '../../utils/compressImage';
import RemoveIcon from '../../components/Icons/RemoveIcon';
import { getFileExtension } from '../../utils/helper';
import InterestVideoIcon from '../../components/Icons/InterestVideoIcon';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import { Button } from '../../components/common/Button';
import ProgressBar from '../../components/common/ProgressBar';
import Checkbox from '../../components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { enforceAPICalling, globalTransparentLoadingPrivate } from '../../redux/slices/authSlice';
import Loader from '../../components/common/Loader';

const InterestForm = ({
  data = {},
  reloadData = () => {},
  type = 'large',
  closePopupHandler = () => {},
  origin,
}) => {
  const dispatch = useDispatch();
  const imageInput = useRef(null);
  const videoInput = useRef(null);
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState({ api: false, delete: false });
  const [title, setTitle] = useState(data?.title);
  const [images, setImages] = useState(
    data?.userInterestMedias?.filter((media) => media?.type === 'image') || [],
  );
  const [videos, setVideos] = useState(
    data?.userInterestMedias?.filter((media) => media?.type === 'video') || [],
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isGlobalTransparentLoadingPrivate = useSelector(
    (state) => state?.auth?.globalTransparentLoadingPrivate,
  );

  const fetchStaticInterestOptions = async () => {
    // The below are the interests that will be used to show in the dropdown list of interests
    const response = await fetchAllStaticInterests();
    const { status, data } = response;

    if (successStatus(status)) {
      const optionsFromAPI = data?.data?.map((opt) => opt?.title);
      setOptions(optionsFromAPI || []);
    }
  };

  useEffect(() => {
    fetchStaticInterestOptions();
  }, []);

  useEffect(() => {
    setTitle(data?.title);
  }, [data?.title]);

  /**
   * This function opens the file browser so that user can choose images and videos to upload
   * @param {*} type
   */
  const handleFileBrowser = (type) => {
    if (isLoading?.api) {
      return;
    }
    if (type === 'photo' && images?.length < LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES) {
      imageInput?.current?.click();
    } else if (type === 'video' && videos?.length < LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS) {
      videoInput?.current?.click();
    } else {
      ToastNotifyError(`Maximum ${LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES} photo files are allowed`);
    }
  };

  /**
   * This function will validate the images, check if they can be uploaded
   */
  const validateImages = async () => {
    if (isLoading?.api) {
      return;
    }

    const filesToUpload = [],
      failedFiles = [];

    if (
      imageInput?.current?.files?.length + images?.length >
      LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES
    ) {
      ToastNotifyError(`Maximum ${LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES} photo files are allowed`);
    }

    for (let i = 0; i < imageInput?.current?.files?.length; i++) {
      if (i + images?.length < LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES) {
        const currentFile = imageInput?.current?.files[i];

        if (INTEREST_PHOTO_TYPES?.includes(currentFile?.type?.toLowerCase())) {
          // If image if greater than INTEREST_MAX_IMAGE_SIZE_BYTES, then this shall not be uploaded
          if (currentFile?.size > LIMITS.INTEREST_MAX_IMAGE_SIZE_BYTES) {
            failedFiles.push(currentFile);
          } else {
            const compressedImage = await compressImage({
              file: currentFile,
            });
            filesToUpload.push(compressedImage);
          }
        } else {
          failedFiles.push(currentFile);
        }
      }
    }

    setImages((prev) => [...filesToUpload, ...prev]);

    if (failedFiles?.length) {
      ToastNotifyError(
        'Some of the files cannot be uploaded. Either the format or the size was in appropriate',
        '',
        false,
      );
    }
  };

  /**
   * This function will validate the images, check if they can be uploaded
   */
  const validateVideos = async () => {
    if (isLoading?.api) {
      return;
    }

    const filesToUpload = [],
      failedFiles = [];

    if (
      videoInput?.current?.files?.length + videos?.length >
      LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS
    ) {
      ToastNotifyError(`Maximum ${LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS} video files are allowed`);
    }

    for (let i = 0; i < videoInput?.current?.files?.length; i++) {
      if (i + videos?.length < LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS) {
        const currentFile = videoInput?.current?.files[i];

        if (INTEREST_VIDEO_TYPES?.includes(currentFile?.type?.toLowerCase())) {
          // If image if greater than INTEREST_MAX_VIDEO_SIZE_IN_BYTES, then this shall not be uploaded
          if (currentFile?.size > LIMITS.INTEREST_MAX_VIDEO_SIZE_IN_BYTES) {
            failedFiles.push(currentFile);
          } else {
            filesToUpload.push(currentFile);
          }
        } else {
          failedFiles.push(currentFile);
        }
      }
    }

    setVideos((prev) => [...filesToUpload, ...prev]);

    if (failedFiles?.length) {
      ToastNotifyError(
        'Some of the files cannot be uploaded. Either the format or the size was in appropriate',
        '',
        false,
      );
    }
  };

  /**
   * This function shall upload all the files to AWS
   * @param {*} filesToUpload
   */
  const uploadFilesOnAWS = async (filesToUpload) => {
    const uploadedMedia = [];

    if (filesToUpload?.length) {
      setIsLoading({ ...isLoading, api: true });
      dispatch(globalTransparentLoadingPrivate(true));

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const response = await fetchGenratePreSignedUrl(getFileExtension(file?.name), 'interest');
        const { status = 0, data = {} } = response;
        if (successStatus(status)) {
          const { key, url } = data?.data || {};
          try {
            await fetchFileUPloadAWS({ url, selectedFile: file });
            uploadedMedia.push({ key: URL.createObjectURL(file), path: key });
          } catch (error) {
            ToastNotifyError('Upload failed for a file');
          }
        }
      }

      setIsLoading({ ...isLoading, api: false });
      dispatch(globalTransparentLoadingPrivate(false));
      return uploadedMedia;
    }
  };

  const interestSubmitHandler = async (values) => {
    if (isLoading?.api) {
      return;
    }

    setIsLoading({ ...isLoading, api: true });
    // dispatch(globalTransparentLoadingPrivate(true));

    let imageUrls = [],
      videoUrls = [];

    const newImages = images?.filter((img) => !img?.path) || []; // These are the new uploaded images
    const newVideos = videos?.filter((vid) => !vid?.path) || []; // These are the new uploaded videos
    const oldImages = images?.filter((img) => img.path)?.map((img) => img?.media) || []; // The old image paths that are already uploaded and are coming from API
    const oldVideos = videos?.filter((vid) => vid.path)?.map((vid) => vid?.media) || []; // The old video paths that are already uploaded and are coming from API

    if (newImages?.length || newVideos?.length) {
      ToastNotifyInfo('Please wait. This may take a few minutes...');
    }

    // Fetching all the AWS urls for images and videos to send in the API
    if (newImages?.length) {
      imageUrls = await uploadFilesOnAWS(newImages);
      imageUrls = imageUrls.map((url) => url?.path);
    }

    if (newVideos?.length) {
      videoUrls = await uploadFilesOnAWS(newVideos);
      videoUrls = videoUrls.map((url) => url?.path);
    }

    let response = {};

    if (data?.id) {
      response = await updateUserInterest({
        id: data?.id,
        title: values.title,
        image: [...imageUrls, ...oldImages],
        video: [...videoUrls, ...oldVideos],
      });
    } else {
      response = await addUserInterest({
        title: values.title,
        image: imageUrls,
        video: videoUrls,
        notifyOthers: values?.notifyOthers,
      });
    }

    const { status, data: responseData } = response;

    if (successStatus(status)) {
      ToastNotifySuccess(`Interest ${data?.id ? 'updated' : 'added'} successfully!`);
      closePopupHandler();

      await reloadData();
      await dispatch(enforceAPICalling(true));
    } else {
      const errormsg = getErrorMessage(responseData);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    }

    setIsLoading({ ...isLoading, api: false });
    dispatch(globalTransparentLoadingPrivate(false));
  };

  const formik = useFormik({
    initialValues: {
      title: title || '', // Update the initial value to an empty string,
      notifyOthers: false,
    },
    validationSchema: validationSchemaTitle,
    onSubmit: interestSubmitHandler,
    enableReinitialize: true,
  });

  const removeMediaFile = (type = 'images', index) => {
    if (type === 'images') {
      const filtered = images.filter((item, _i) => _i !== index);
      setImages(filtered);
    } else {
      const filtered = videos.filter((item, _i) => _i !== index);
      setVideos(filtered);
    }
  };

  // This function creates image URLs from file objects and renders them
  const renderImages = (files) => {
    return files.map((file, index) => {
      // If the file is already uploaded, then it will contain path (from API) else it will be "File" object
      const imageUrl = file?.path || URL.createObjectURL(file);
      return (
        <div key={index} className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px]">
          <img
            src={imageUrl}
            alt={file.name}
            className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
          />
          <div
            className="absolute right-[-6px] top-[-6px] cursor-pointer"
            onClick={() => removeMediaFile('images', index)}
          >
            <RemoveIcon />
          </div>
        </div>
      );
    });
  };

  // This function creates video URLs from file objects and renders them
  const renderVideos = (files) => {
    return files.map((file, index) => {
      // If the file is already uploaded, then it will contain path (from API) else it will be "File" object
      let imageUrl = file?.path || URL.createObjectURL(file);

      return (
        <div key={index} className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px]">
          <video
            src={imageUrl}
            className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
            controls={false}
          />
          <div className="rounded-md w-full h-full absolute top-0 left-0 bg-[#00000085] flex items-center justify-center">
            <InterestVideoIcon />
          </div>
          <div
            className="absolute right-[-6px] top-[-6px] cursor-pointer"
            onClick={() => removeMediaFile('videos', index)}
          >
            <RemoveIcon />
          </div>
        </div>
      );
    });
  };

  const deleteInterestHandler = async () => {
    if (isLoading?.delete) return;

    setIsLoading({ ...isLoading, delete: true });
    const response = await deleteInterest({ id: data?.id });
    const { status, data: responseData } = response;
    if (!successStatus(status)) {
      const errormsg = getErrorMessage(responseData);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    } else {
      setIsDeleteModalOpen(false);
      formik?.resetForm();
      formik.setFieldValue('title', '');
      await reloadData();
      closePopupHandler();
    }
    setIsLoading({ ...isLoading, delete: false });
  };

  return (
    <div
      className={`relative w-full ${
        isGlobalTransparentLoadingPrivate ? 'pointer-events-none' : ''
      }`}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={`${type === 'small' ? '' : 'md:flex'} block items-center`}>
          <div
            className={`w-[155px] ${
              type === 'small'
                ? 'text-[14px] font-medium capitalize'
                : 'md:pb-0 form-title mt-[-15px]'
            } pb-2`}
          >
            Title
          </div>
          <div className={'flex flex-col items-start'}>
            <AutoComplete
              options={options}
              name="title" // Add name attribute for Formik
              value={formik.values.title}
              defaultValue={formik.values.title || ''}
              onChange={(val) => formik.setFieldValue('title', val)}
              parentClassNames={` ${type === 'small' ? 'w-full' : 'md:w-[400px] w-full'}`}
              maxLength={LIMITS.MAX_TITLE_LENGTH}
            />
            <div className="mt-1 error min-h-[18px]">
              {formik.touched.title && formik.errors.title ? formik.errors.title : ''}
            </div>
          </div>
        </div>

        <div className={`${type === 'small' ? '' : 'md:flex'} block mt-3 mb-5`}>
          <div
            className={`${
              type === 'small'
                ? 'text-[14px] font-medium capitalize w-full'
                : 'w-[155px] md:pb-0 form-title mt-4'
            } pb-2`}
          >
            Upload Photo
          </div>
          <div className="flex flex-col">
            <div
              className={`${
                type === 'small' ? '' : 'md:w-[240px]'
              } py-[16px]  border-dashed border border-blueprimary rounded-lg cursor-pointer text-center`}
              onClick={() => handleFileBrowser('photo')}
              label="Attach Document"
            >
              <div className="flex items-center justify-center">
                <span className="mr-2">
                  <UploadIcon />
                </span>
                <span className="upload-btn">Upload Photo</span>
              </div>
              <input
                className="hidden"
                id="attach-document"
                multiple
                ref={imageInput}
                type="file"
                onInput={() => validateImages()}
                onClick={(e) => {
                  e.target.value = null;
                }} // We are setting this to null because we want to be able to select the same file simultaneously
                accept={INTEREST_PHOTO_TYPES}
              />
            </div>
            {images?.length ? (
              <div className="py-4">
                <div className="font-normal text-greydark text-[10px] md:text-[14px] ">
                  Photos ({images?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_IMAGES})
                </div>
                <div className="flex gap-3 pt-2 flex-wrap">{renderImages(images)}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className={`${type === 'small' ? '' : 'md:flex'} block mt-8 mb-5`}>
          <div
            className={`${
              type === 'small'
                ? 'text-[14px] font-medium capitalize'
                : 'md:pb-0 form-title w-[155px] mt-4'
            } pb-2`}
          >
            Upload Video
          </div>
          <div className="flex flex-col">
            <div
              className={`py-[16px] ${
                type === 'small' ? '' : 'md:w-[240px]'
              }  border-dashed border border-blueprimary rounded-lg cursor-pointer text-center`}
              onClick={() => videoInput.current.click()}
              label="Attach Document"
            >
              <div className="flex items-center justify-center">
                <span className="mr-2">
                  <UploadIcon />
                </span>
                <span className="upload-btn">Upload Video</span>
              </div>
              <input
                className="hidden"
                id="attach-document"
                multiple
                ref={videoInput}
                type="file"
                accept={INTEREST_VIDEO_TYPES}
                onInput={() => validateVideos()}
                onClick={(e) => {
                  e.target.value = null;
                }} // We are setting this to null because we want to be able to select the same file simultaneously
              />
            </div>
            {videos?.length ? (
              <div className="py-4">
                <div className="font-normal text-greydark text-[10px] md:text-[14px] ">
                  Videos ({videos?.length}/{LIMITS.INTEREST_MAX_NUMBER_OF_VIDEOS})
                </div>
                <div className="flex gap-3 pt-2 flex-wrap">{renderVideos(videos)}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        {!data?.id && origin !== 'SIGNUP' ? (
          <div className="flex md:gap-[10px] gap-[3px] text-[12px] mb-4 md:text-[16px] md:pb-0 pb-6">
            <Checkbox
              checked={formik?.notifyOthers}
              setChecked={(value) => {
                formik.setFieldValue('notifyOthers', value);
              }}
              width="w-4"
              height="h-4"
            />{' '}
            <span className="text-[12px]">
              Do you want to notify your connections about your profile update?
            </span>
          </div>
        ) : (
          ''
        )}

        {isLoading?.api ? (
          <ProgressBar progress={30} className="w-full mb-4" />
        ) : (
          <div className="bg-greymedium h-[1px] w-full my-4" />
        )}

        <div className="flex justify-between">
          {data?.id ? (
            <Button
              showArrowIcon={false}
              label="Delete"
              isDelete
              onClick={() => setIsDeleteModalOpen(true)}
              isLoading={isLoading?.delete}
              isDisabled={isLoading?.delete}
              onlyShowLoaderWhenLoading={true}
              additionalClassNames="text-[14px]"
            />
          ) : (
            ''
          )}

          <OutlinedButton
            label="Save"
            showArrowIcon={false}
            disabled={isLoading?.api}
            isLoading={isLoading?.api}
            type="submit"
            additionalClassNames="ml-auto"
          />
        </div>
      </form>
      <ConfirmationModal
        title="Delete Interest"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        primaryButtonTitle="Delete"
        primaryButtonAction={() => deleteInterestHandler()}
        secondaryButtonTitle="Cancel"
        secondaryButtonAction={() => setIsDeleteModalOpen(false)}
        isPrimaryButtonDisabled={isLoading?.delete}
      >
        Are you sure you want to delete this Interest?
      </ConfirmationModal>

      {isGlobalTransparentLoadingPrivate ? (
        <div className="fixed top-0 left-0 bg-[#b3b3b366] h-screen w-screen z-[510] pointer-events-none">
          <Loader />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default InterestForm;
