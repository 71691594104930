import { useEffect, useState } from 'react';
import { UploadIcon } from '../../components/Icons/UploadIcon';
import { CAREER_MEDIA_TYPES, IMAGE_EXTENSIONS, LIMITS } from '../../constants/constants';
import {
  fetchCareerMediaList,
  fetchFileUPloadAWS,
  fetchGenratePreSignedUrl,
  updateCareerMedia,
} from '../../services/signup';
import { getErrorMessage, successStatus } from '../../common';
import {
  ToastNotifyError,
  ToastNotifyInfo,
  ToastNotifySuccess,
} from '../../components/Toast/ToastNotify';
import { getFileExtension, getFileName } from '../../utils/helper';
import compressImage from '../../utils/compressImage';
import OutlinedButton from '../../components/common/OutlinedButton';
import RemoveIcon from '../../components/Icons/RemoveIcon';
import InterestVideoIcon from '../../components/Icons/InterestVideoIcon';
import ProgressBar from '../../components/common/ProgressBar';
import { globalTransparentLoadingPrivate } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';

/**
 * Form where user can update the Work section's media files
 * @param {*} param0
 * @returns
 */
export function MediaContent({ mediaRef, careerId = null }) {
  const dispatch = useDispatch();
  const [media, setMedia] = useState([]);
  const [mediaFromAPI, setMediaFromAPI] = useState([]);
  const [isLoading, setIsLoading] = useState({
    global: true,
    api: false,
  });

  useEffect(() => {
    if (!careerId) {
      setMediaFromAPI([]);
      setMedia([]);
    }
  }, [careerId]);

  const getMedia = async () => {
    setIsLoading({ ...isLoading, global: true });
    const response = await fetchCareerMediaList(careerId);
    const { status, data = {} } = response;
    if (successStatus(status)) {
      setMedia(data?.data);
      setMediaFromAPI(data?.data);
    } else {
      setMedia([]);
      setMediaFromAPI([]);
    }
    setIsLoading({ ...isLoading, global: false });
  };

  useEffect(() => {
    getMedia();
  }, []);

  /**
   * This function will validate the media, check if they can be uploaded
   */
  const validateMedia = async () => {
    if (isLoading?.api) {
      return;
    }

    const filesToUpload = [],
      failedFiles = [];

    if (mediaRef?.current?.files?.length + media?.length > LIMITS.CAREER_MAX_NUMBER_OF_MEDIA) {
      ToastNotifyError(`Maximum ${LIMITS.CAREER_MAX_NUMBER_OF_MEDIA} media files are allowed`);
    }

    for (let i = 0; i < mediaRef?.current?.files?.length; i++) {
      if (i + media?.length < LIMITS.CAREER_MAX_NUMBER_OF_MEDIA) {
        const currentFile = mediaRef?.current?.files[i];

        if (currentFile?.type?.includes('image/')) {
          // If image if greater than POST_MAX_IMAGE_SIZE_IN_BYTES, then this shall not be uploaded
          if (currentFile?.size > LIMITS.CAREER_MAX_IMAGE_SIZE_BYTES) {
            failedFiles.push(mediaRef?.current?.files[i]);
          } else {
            const compressedImage = await compressImage({
              file: mediaRef?.current?.files[i],
            });
            filesToUpload.push(compressedImage);
          }
        } else if (currentFile?.type?.includes('video/')) {
          // If video if greater than CAREER_MAX_VIDEO_SIZE_IN_BYTES, then this shall not be uploaded
          if (currentFile?.size > LIMITS.CAREER_MAX_VIDEO_SIZE_IN_BYTES) {
            failedFiles.push(mediaRef?.current?.files[i]);
          } else {
            filesToUpload.push(mediaRef?.current?.files[i]);
          }
        }
      }
    }

    setMedia((prev) => [...filesToUpload, ...prev]);

    if (failedFiles?.length) {
      ToastNotifyError(
        'Some of the files cannot be uploaded. Either the format or the size was in appropriate',
        '',
        false,
      );
    }
  };

  /**
   * This function shall upload all the files to AWS
   * @param {*} filesToUpload
   */
  const uploadFilesOnAWS = async (filesToUpload) => {
    const uploadedMedia = [];

    if (filesToUpload?.length) {
      setIsLoading({ ...isLoading, api: true });
      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];
        const response = await fetchGenratePreSignedUrl(getFileExtension(file?.name), 'career');
        const { status = 0, data = {} } = response;
        if (successStatus(status)) {
          const { key, url } = data?.data || {};
          try {
            await fetchFileUPloadAWS({ url, selectedFile: file });
            uploadedMedia.push({ key: URL.createObjectURL(file), path: key });
          } catch (error) {
            ToastNotifyError('Upload failed for a file');
          }
        }
      }
    }

    setIsLoading({ ...isLoading, api: false });
    return uploadedMedia;
  };

  const mediaSubmitHandler = async () => {
    if (isLoading?.api) {
      return;
    }

    setIsLoading({ ...isLoading, api: true });
    dispatch(globalTransparentLoadingPrivate(true));

    let mediaUrls = [];

    const newMedia = media?.filter((media) => !('path' in media)) || []; // These are the new uploaded images
    const oldMedia = media?.filter((media) => 'path' in media)?.map((media) => media?.path) || []; // The old image paths that are already uploaded and are coming from API

    if (newMedia?.length) {
      ToastNotifyInfo('Please wait. This may take a few minutes...');
    }

    // Fetching all the AWS urls for images and videos to send in the API
    if (newMedia?.length) {
      mediaUrls = await uploadFilesOnAWS(newMedia);
      mediaUrls = mediaUrls?.map((url) => url?.path);
    }

    let response = {};

    response = await updateCareerMedia({
      id: careerId,
      media: [...mediaUrls, ...oldMedia],
    });

    const { status, data: responseData } = response;

    if (successStatus(status)) {
      ToastNotifySuccess('Media updated successfully!');

      await getMedia();
    } else {
      const errormsg = getErrorMessage(responseData);
      if (errormsg) {
        ToastNotifyError(errormsg);
      }
    }

    setIsLoading({ ...isLoading, api: false });
    dispatch(globalTransparentLoadingPrivate(false));
  };

  const removeMediaFile = (index) => {
    const filtered = media.filter((item, _i) => _i !== index);
    setMedia(filtered);
  };

  // This function creates image URLs from file objects and renders them
  const renderMedia = (files) => {
    return files.map((file, index) => {
      // If the file is already uploaded, then it will contain path (from API) else it will be "File" object
      const url = 'media' in file ? file?.media : URL.createObjectURL(file);

      return (
        <div key={index} className="relative max-h-[55px] h-[55px] w-[55px] max-w-[55px]">
          {IMAGE_EXTENSIONS.includes(getFileExtension(getFileName(file?.media || file?.name))) ? (
            <img
              src={url}
              alt={file.name}
              className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
            />
          ) : (
            <>
              <video
                src={url}
                className="mb-3 max-h-[55px] h-[55px] w-[55px] max-w-[55px] rounded-md object-cover bg-[#c6b7b7]"
                controls={false}
              />
              <div className="rounded-md w-full h-full absolute top-0 left-0 bg-[#00000085] flex items-center justify-center">
                <InterestVideoIcon />
              </div>
            </>
          )}

          <div
            className="absolute right-[-6px] top-[-6px] cursor-pointer"
            onClick={() => removeMediaFile(index)}
          >
            <RemoveIcon />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="text-area-input max-w-[796px]">
        Please upload media related to this career field. You can add combination of images and
        videos. You can upload up to {LIMITS.CAREER_MAX_NUMBER_OF_MEDIA} media. <br />
      </div>
      <p className="text-[10px] my-3">
        <strong>Note:</strong> Only {CAREER_MEDIA_TYPES?.join(', ')} formats are allowed. Max
        allowed each photo of 10 MB and video of 50 MB
      </p>
      <div className="mt-4 pb-6">
        <div
          className={`py-[16px] w-[240px] border-dashed border border-blueprimary rounded-lg cursor-pointer text-center ${
            media?.length < LIMITS.CAREER_MAX_NUMBER_OF_MEDIA
              ? ''
              : '!cursor-not-allowed opacity-50'
          }`}
          onClick={() => {
            media?.length < LIMITS.CAREER_MAX_NUMBER_OF_MEDIA ? mediaRef.current.click() : {};
          }}
          label="Attach Document"
        >
          <div className="flex items-center justify-center">
            <span className="mr-2">
              <UploadIcon />
            </span>
            <span className="upload-btn">Upload photos/videos</span>
          </div>

          <input
            className="hidden"
            id="attach-document"
            multiple
            ref={mediaRef}
            type="file"
            accept={CAREER_MEDIA_TYPES}
            onInput={() => validateMedia()}
            onClick={(e) => {
              e.target.value = null;
            }} // We are setting this to null because we want to be able to select the same file simultaneously
          />
        </div>

        {media?.length ? (
          <div>
            <div className="text-[12px] font-medium my-3">
              ({media?.length}/{LIMITS.CAREER_MAX_NUMBER_OF_MEDIA}) files uploaded
            </div>
            <div className="flex gap-3 pt-2 flex-wrap">{renderMedia(media)}</div>
          </div>
        ) : (
          ''
        )}

        {isLoading?.api ? (
          <ProgressBar progress={30} className="w-full my-4" />
        ) : (
          <div className="bg-greymedium h-[1px] w-full my-4" />
        )}

        <div className={'flex pt-6 pb-5 justify-end'}>
          <OutlinedButton
            label="Save"
            onClick={mediaSubmitHandler}
            type="button"
            isLoading={isLoading?.api}
            onlyShowLoaderWhenLoading={true}
            disabled={JSON.stringify(media) === JSON.stringify(mediaFromAPI)}
          />
        </div>
      </div>
    </>
  );
}
