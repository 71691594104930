import React, { useEffect, useState } from 'react';
import Card from '../common/Card';
import OutlinedButton from '../common/OutlinedButton';
import edit from '../../assets/images/editIcon.svg';
import { successStatus } from '../../common';
import SpinningLoader from '../common/SpinningLoader';
import noWork from '../../assets/images/noWork.svg';
import Modal from '../Modal';
import { getMySelfDetails, getOtherUserMyselfDetails } from '../../services/myself';
import AddAboutMe from '../../components/MyselfPage/AddAboutMe';
import Location from '../../components/MyselfPage/Location';
import MoreAboutYou from '../../components/MyselfPage/MoreAboutYou';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../constants/urlPaths';
import Assesments from '../MyselfPage/Assesments';
import { DISC, ENNEAGRAM, HOLLAND_CODE, MYERS_BRIGGS } from '../../constants/constants';
import downArrow from '../../assets/images/downArrow.svg';

function Myself({ otherUserId = null }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({ global: false, edit: false });
  const [location, setLocation] = useState({});
  const [aboutMyself, setAboutMyself] = useState('');
  const [isOpenAboutMyselfModal, setIsOpenAboutMyselfModal] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isMoreAboutYouOpen, setIsMoreAboutYouOpen] = useState(false);
  const [softSkillsData, setSoftSkillsData] = useState([]);
  const [pronounValue, setPronounValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [languagesData, setLanguagesData] = useState([]);
  const [assessmentBigfiveData, setAssessmentBigfiveData] = useState([]);
  const [assessmmentAttributesData, setAssessmmentAttributesData] = useState([]);
  const [assessmmentMyresData, setAssessmmentMyresData] = useState([]);
  const [assessmmentEnneagramData, setAssessmmentEnneagramData] = useState([]);
  const [assessmmentDiscData, setAssessmmentDiscData] = useState([]);
  const [assessmmentHollandData, setAssessmmentHollandData] = useState([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [openAccordions, setOpenAccrodions] = useState([
    {
      bigFive: false,
    },
    {
      attributes: false,
    },
    {
      myersBriggs: false,
    },
    {
      disc: false,
    },
    {
      enneagram: false,
    },
    {
      hollandCode: false,
    },
  ]);

  const renderFormatData = (data) => {
    const formattedText = data.split('\n').map((str, index) => (
      <div key={index}>
        {str}
        <br />
      </div>
    ));
    return formattedText;
  };

  useEffect(() => {
    if (isSaveClicked) {
      setIsOpenAboutMyselfModal(false);
      setIsLocationModalOpen(false);
      setIsMoreAboutYouOpen(false);
      setIsSaveClicked(false);
    }
  }, [isSaveClicked]);

  const constructLocationData = (data) => {
    const raisedDataArray = [];
    const bornDataArray = [];
    const currentDataArray = [];
    data?.forEach((element) => {
      if (element.type === 'current') {
        currentDataArray.push(element);
      } else if (element.type === 'raised') {
        raisedDataArray.push(element);
      } else {
        bornDataArray.push(element);
      }
    });
    setLocation({
      current: currentDataArray,
      raised: raisedDataArray,
      born: bornDataArray,
    });
  };

  const getMyselfData = async () => {
    if (!isLoading?.global) {
      setIsLoading({ ...isLoading, global: true });
      let response = {};
      if (otherUserId) {
        response = await getOtherUserMyselfDetails({ userId: otherUserId });
        const { status, data } = response;
        if (successStatus(status)) {
          setLocation(data?.data?.groupedLocations || []);
          setAboutMyself(data?.data?.aboutMyself || '');
          setSoftSkillsData(data?.data?.softSkills || []);
          setPronounValue((data?.data?.pronoun && data?.data?.pronoun[0]) || '');
          setGenderValue((data?.data?.gender && data?.data?.gender[0]) || '');
          setLanguagesData(data?.data?.userLanguages || []);
          setAssessmentBigfiveData(data?.data?.bigFive || []);
          setAssessmmentAttributesData(data?.data?.attributes || []);
          setAssessmmentMyresData(data?.data?.otherAssessment?.myersBriggsAssessment || '');
          setAssessmmentHollandData(data?.data?.otherAssessment?.hollandCode || '');
          setAssessmmentEnneagramData(data?.data?.otherAssessment?.enneagram || '');
          setAssessmmentDiscData(data?.data?.otherAssessment?.discAssessment || '');
        }
      } else {
        response = await getMySelfDetails();
        const { status, data } = response;
        if (successStatus(status)) {
          setLocation(data?.data?.locations || []);
          setAboutMyself(data?.data?.aboutMyself || '');
          setSoftSkillsData(data?.data?.softSkills || []);
          setPronounValue((data?.data?.pronoun && data?.data?.pronoun[0]) || '');
          setGenderValue((data?.data?.genderIdentity && data?.data?.genderIdentity[0]) || '');
          setLanguagesData(data?.data?.languageAndProficiency || []);
          setAssessmentBigfiveData(data?.data?.bigFiveAssessment || []);
          setAssessmmentAttributesData(data?.data?.attributeAssessment || []);
          setAssessmmentMyresData(data?.data?.myersBriggsAssessment || '');
          setAssessmmentHollandData(data?.data?.hollandCode || '');
          setAssessmmentEnneagramData(data?.data?.enneagram || '');
          setAssessmmentDiscData(data?.data?.discAssessment || '');
          constructLocationData(data?.data?.locations);
        }
      }
      setIsLoading({ ...isLoading, global: false });
    }
  };

  useEffect(() => {
    getMyselfData();
  }, []);

  useEffect(() => {
    getMyselfData();
  }, [otherUserId]);

  const renderAboutMyself = () => {
    if (aboutMyself) {
      return (
        <div className="p-4 flex flex-col w-full justify-between">
          <div className="flex gap-2 justify-between items-center">
            <div className="text-blueprimary text-[16px] font-semibold">About Me</div>

            {otherUserId ? (
              ''
            ) : (
              <div className="flex items-center justify-between">
                <div
                  className="bg-iconBackground p-1 rounded cursor-pointer"
                  onClick={() => setIsOpenAboutMyselfModal(true)}
                >
                  <img src={edit} alt="edit" />
                </div>
              </div>
            )}
          </div>
          <div className="font-normal text-greydark text-[14px] my-3 text-justify">
            {renderFormatData(aboutMyself)}
          </div>
        </div>
      );
    } else if (!aboutMyself && !otherUserId) {
      return (
        <>
          <p className="px-4 pt-4 w-full md:w-[80%] text-[16px] font-semibold text-greydark">
            Most issues related to person-job compatibility are connected to interpersonal skills
            and personality. Share more about who you are. It helps us match you with the right
            opportunities.
          </p>

          <div className="text-center flex mb-4 px-4 pt-4 hover:opacity-70">
            <OutlinedButton
              label={'Add About you'}
              showArrowIcon={false}
              onClick={() => setIsOpenAboutMyselfModal(true)}
            />
          </div>
        </>
      );
    }
  };

  const renderMyselfLocation = () => {
    if (location?.current?.length || location?.raised?.length || location?.born?.length) {
      return (
        <div className="flex flex-col gap-6 mt-6">
          <div className="p-[18px] rounded-[4px]" style={{ background: 'rgba(0, 113, 188, 0.05)' }}>
            <div className="text-[#0071BC] text-base font-semibold">Location</div>
          </div>
          <div className="p-4 py-[0px] mb-[24px] flex w-full justify-between items-start">
            <div className="w-full">
              <div className="flex flex-col gap-y-[24px]">
                {location?.current && location?.current?.length !== 0 ? (
                  <div>
                    <div className="text-[#0071BC] text-[16px] font-semibold">Current</div>
                    <div className="flex flex-col gap-y-[24px] mt-[16px]">
                      {location?.current?.map((item, index) => {
                        return (
                          <div
                            key={index + item?.id}
                            className="grid grid-cols-1 sm:grid-cols-5 gap-y-4"
                          >
                            <div className="flex flex-row sm:flex-col justify-between">
                              <div className="text-[#333] text-[14px] font-normal">Country</div>
                              <div className="text-[#333] text-[16px] font-semibold">
                                {item.country}
                              </div>
                            </div>
                            {item.state ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">State</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.state}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.city ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">City</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.city}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.duration ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">
                                  Years (How Long)
                                </div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.duration}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {location?.raised && location?.raised?.length !== 0 ? (
                  <div>
                    <div className="text-[#0071BC] text-[16px] font-semibold">Raised</div>
                    <div className="flex flex-col gap-y-[24px] mt-[16px]">
                      {location?.raised?.map((item, index) => {
                        return (
                          <div
                            key={index + item?.id}
                            className="grid grid-cols-1 sm:grid-cols-5 gap-y-4"
                          >
                            <div className="flex flex-row sm:flex-col justify-between">
                              <div className="text-[#333] text-[14px] font-normal">Country</div>
                              <div className="text-[#333] text-[16px] font-semibold">
                                {item.country}
                              </div>
                            </div>
                            {item.state ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">State</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.state}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.city ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">City</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.city}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {location?.born && location?.born?.length !== 0 ? (
                  <div>
                    <div className="text-[#0071BC] text-[16px] font-semibold">Born</div>
                    <div className="flex flex-col gap-y-[24px] mt-[16px]">
                      {location?.born?.map((item, index) => {
                        return (
                          <div
                            key={index + item?.id}
                            className="grid grid-cols-1 sm:grid-cols-5 gap-y-4"
                          >
                            <div className="flex flex-row sm:flex-col justify-between">
                              <div className="text-[#333] text-[14px] font-normal">Country</div>
                              <div className="text-[#333] text-[16px] font-semibold">
                                {item.country}
                              </div>
                            </div>
                            {item.state ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">State</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.state}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {item.city ? (
                              <div className="flex flex-row sm:flex-col justify-between">
                                <div className="text-[#333] text-[14px] font-normal">City</div>
                                <div className="text-[#333] text-[16px] font-semibold">
                                  {item.city}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {otherUserId ? (
              ''
            ) : (
              <div className="flex items-center justify-between">
                <div
                  className="bg-iconBackground p-1 rounded cursor-pointer"
                  onClick={() => setIsLocationModalOpen(true)}
                >
                  <img src={edit} alt="edit" />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (!otherUserId) {
      return (
        <>
          {/* <hr className="" style={{ color: 'rgba(161, 160, 160, 0.50)' }} /> */}
          <p className="px-4 pt-4 w-full md:w-[80%] text-[16px] font-semibold text-greydark">
            The places you have been and the culture you have experienced are also part of your
            story. Sharing it might bring interesting opportunities to you.
          </p>
          <div className="text-center flex mb-4 px-4 pt-4 hover:opacity-70">
            <OutlinedButton
              label={'Add Location'}
              showArrowIcon={false}
              onClick={() => setIsLocationModalOpen(true)}
            />
          </div>
        </>
      );
    }
  };

  const renderMoreAboutYou = () => {
    if (softSkillsData?.length || languagesData?.length || pronounValue || genderValue) {
      return (
        <div className="flex flex-col gap-6 mt-6 relative">
          <div className="p-[18px] rounded-[4px]" style={{ background: 'rgba(0, 113, 188, 0.05)' }}>
            <div className="text-[#0071BC] text-base font-semibold">Soft Skills/Language</div>
          </div>
          <div className="p-4 py-[0px] mb-[16px] flex w-full justify-between items-start">
            <div className="flex flex-col gap-y-[24px]">
              {softSkillsData.length !== 0 ? (
                <div>
                  <div className="text-[#0071BC] text-[16px] font-semibold">Soft Skills</div>
                  <div className="flex sm:gap-x-[1vw] gap-x-[10px] gap-y-[10px] mt-[10px] flex-wrap">
                    {softSkillsData.map((item, index) => {
                      return (
                        <div
                          className="text-[#333] text-[14px] font-normal rounded-[4px] text-center px-[10px] py-[7px] break-all capitalize	"
                          style={{ background: 'rgba(0, 113, 188, 0.05)' }}
                          key={index + item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              {languagesData.length !== 0 ? (
                <div>
                  <div className="text-[#0071BC] text-[16px] font-semibold">
                    Language / Proficiency
                  </div>
                  <div className="flex flex-col gap-y-[10px] mt-[10px]">
                    {languagesData.map((item, index) => {
                      return (
                        <div
                          key={index + item?.id}
                          className="text-[#333] text-[14px] font-semibold"
                        >{`${item?.language} / ${item?.proficiency}`}</div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              {genderValue ? (
                <div>
                  <div className="text-[#0071BC] text-[16px] font-semibold">Gender identity</div>
                  <div className="flex gap-x-[1vw] mt-[10px]">
                    <div className="text-[#333] text-[14px] font-semibold">{genderValue}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {pronounValue ? (
                <div>
                  <div className="text-[#0071BC] text-[16px] font-semibold">Pronoun</div>
                  <div className="flex gap-x-[1vw] mt-[10px]">
                    <div className="text-[#333] text-[14px] font-semibold">{pronounValue}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {otherUserId ? (
              ''
            ) : (
              <div className="absolute right-[17px] top-[75px] flex items-center justify-between">
                <div
                  className="bg-iconBackground p-1 rounded cursor-pointer"
                  onClick={() => setIsMoreAboutYouOpen(true)}
                >
                  <img src={edit} alt="edit" />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (!otherUserId) {
      return (
        <>
          {/* <hr className="" style={{ color: 'rgba(161, 160, 160, 0.50)' }} /> */}
          <p className="px-4 pt-4 w-full md:w-[80%] text-[16px] font-semibold text-greydark">
            Most issues related to person-job compatibility are connected to interpersonal skills
            and personality. Share more about who you are. It helps us match you with the right
            opportunities.
          </p>
          <div className="text-center flex mb-4 px-4 pt-4 hover:opacity-70">
            <OutlinedButton
              label={'Add More About You'}
              showArrowIcon={false}
              onClick={() => setIsMoreAboutYouOpen(true)}
            />
          </div>
        </>
      );
    }
  };

  const checkObjectValuesEmpty = (data) => {
    const { id, createdAt, updatedAt, UserId, ...rest } = data;
    return Object.values(rest).some((value) => value !== null);
  };

  const renderAssesments = () => {
    if (
      (assessmmentDiscData ||
        assessmmentEnneagramData ||
        assessmmentHollandData ||
        assessmmentMyresData ||
        checkObjectValuesEmpty(assessmentBigfiveData) ||
        checkObjectValuesEmpty(assessmmentAttributesData)) &&
      !otherUserId
    ) {
      return (
        <Assesments
          discAssessment={assessmmentDiscData}
          enneagram={assessmmentEnneagramData}
          hollandCode={assessmmentHollandData}
          myersBriggsAssessment={assessmmentMyresData}
          attributeAssessment={assessmmentAttributesData}
          bigFiveAssessment={assessmentBigfiveData}
        />
      );
    } else if (
      otherUserId &&
      (assessmmentDiscData ||
        assessmmentEnneagramData ||
        assessmmentHollandData ||
        assessmmentMyresData ||
        checkObjectValuesEmpty(assessmentBigfiveData) ||
        checkObjectValuesEmpty(assessmmentAttributesData))
    ) {
      const { id, UserId, createdAt, updatedAt, ...restBigFiveData } = assessmentBigfiveData;
      const {
        id: attributeId,
        UserId: attributeUserId,
        createdAt: attributeCreatedAt,
        updatedAt: attributeUpdateAt,
        ...restAttributeData
      } = assessmmentAttributesData;
      return (
        <div className="flex flex-col gap-6 mt-6">
          <div className="p-[18px] rounded-[4px]" style={{ background: 'rgba(0, 113, 188, 0.05)' }}>
            <div className="text-[#0071BC] text-base font-semibold">Assessments</div>
          </div>
          <div className=" px-[18px] pb-[20px]">
            <style>
              {`
                .not-last-child:last-child {
                  display: none;
                }
              `}
            </style>
            {Object.values(restBigFiveData).some((value) => value !== null) ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'bigFive') {
                            return { ...item, bigFive: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">The Big Five</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${openAccordions[0]?.bigFive ? 'rotate-180' : ''}`}
                    />
                  </div>
                  {openAccordions[0]?.bigFive ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-y-4 mt-[20px]">
                      {Object.entries(restBigFiveData).map(([key, value], index) => {
                        if (value) {
                          return (
                            <div
                              key={index + key}
                              className="flex flex-row sm:flex-col justify-between"
                            >
                              <div className="text-[16px] font-medium text-[#0071BC] capitalize">
                                {key}
                              </div>
                              <div className="text-[16px] font-medium text-[#333]">{value}</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
            {assessmmentMyresData ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'myersBriggs') {
                            return { ...item, myersBriggs: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">Myers-Briggs</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${
                        openAccordions[2]?.myersBriggs ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                  {MYERS_BRIGGS.map((obj) => {
                    if (obj.value === assessmmentMyresData && openAccordions[2]?.myersBriggs) {
                      return (
                        <div key={obj.title} className="mt-[20px]">
                          <div className="text-[16px] font-medium text-[#0071BC] capitalize mb-[12px]">
                            {obj?.title}
                          </div>
                          <div className="text-[16px] font-medium text-[#333]">
                            {obj?.description}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
            {Object.values(restAttributeData).some((value) => value !== null) ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'attributes') {
                            return { ...item, attributes: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">The Attributes</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${
                        openAccordions[1]?.attributes ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                  {openAccordions[1]?.attributes ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-y-4 mt-[20px]">
                      {Object.entries(restAttributeData).map(([key, value], index) => {
                        if (value) {
                          return (
                            <div
                              key={index + key}
                              className="flex flex-row sm:flex-col justify-between"
                            >
                              <div className="text-[16px] font-medium text-[#0071BC] capitalize">
                                {key}
                              </div>
                              <div className="text-[16px] font-medium text-[#333]">{value}</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
            {assessmmentDiscData ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'disc') {
                            return { ...item, disc: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">DISC</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${openAccordions[3]?.disc ? 'rotate-180' : ''}`}
                    />
                  </div>
                  {DISC.map((obj) => {
                    if (obj.value === assessmmentDiscData && openAccordions[3]?.disc) {
                      return (
                        <div key={obj.title} className="mt-[20px]">
                          <div className="text-[16px] font-medium text-[#0071BC] capitalize mb-[12px]">
                            {obj?.title}
                          </div>
                          <div className="text-[16px] font-medium text-[#333]">
                            {obj?.description}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
            {assessmmentEnneagramData ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'enneagram') {
                            return { ...item, enneagram: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">Enneagram</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${
                        openAccordions[4]?.enneagram ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                  {ENNEAGRAM.map((obj) => {
                    if (obj.value === assessmmentEnneagramData && openAccordions[4]?.enneagram) {
                      return (
                        <div key={obj.title} className="mt-[20px]">
                          <div className="text-[16px] font-medium text-[#0071BC] capitalize mb-[12px]">
                            {obj?.title}
                          </div>
                          <div className="text-[16px] font-medium text-[#333]">
                            {obj?.description}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
            {assessmmentHollandData ? (
              <>
                <div>
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() =>
                      setOpenAccrodions((prevState) => {
                        return prevState.map((item) => {
                          if (Object.keys(item)[0] === 'hollandCode') {
                            return { ...item, hollandCode: !Object.values(item)[0] };
                          }
                          return item;
                        });
                      })
                    }
                  >
                    <div className="text-[20px] font-medium text-[#0071BC]">Holland Code</div>
                    <img
                      src={downArrow}
                      alt="Down Arrow"
                      className={`cursor-pointer ${
                        openAccordions[5]?.hollandCode ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                  {HOLLAND_CODE.map((obj) => {
                    if (obj.value === assessmmentHollandData && openAccordions[5]?.hollandCode) {
                      return (
                        <div key={obj.title} className="mt-[20px]">
                          <div className="text-[16px] font-medium text-[#0071BC] capitalize mb-[12px]">
                            {obj?.title}
                          </div>
                          <div className="text-[16px] font-medium text-[#333]">
                            {obj?.description}
                          </div>
                          <div className="text-[16px] font-medium text-[#333]">{obj?.heading1}</div>
                          {obj.listArray.map((item, index) => {
                            return (
                              <div
                                key={index + item}
                                className="text-[16px] font-medium text-[#333]"
                              >
                                {item}
                              </div>
                            );
                          })}
                          <div className="text-[16px] font-medium text-[#333]">{obj.heading2}</div>
                          <div className="text-[16px] font-medium text-[#333]">
                            {obj.subHeading2}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <hr
                  className=" my-[23px] not-last-child"
                  style={{ color: 'rgba(161, 160, 160, 0.50)' }}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    } else if (!otherUserId) {
      return (
        <>
          {/* <hr className="" style={{ color: 'rgba(161, 160, 160, 0.50)' }} /> */}
          <p className="px-4 pt-4 w-full md:w-[80%] text-[16px] font-semibold text-greydark">
            Research shows that most organizations use personality or behavioral assessment in
            hiring. Have you taken any of them? If so, you can share the results here. It will help
            them in their search for someone with the personal attributes you have.
          </p>
          <div className="text-center flex mb-4 px-4 pb-12 pt-4 hover:opacity-70">
            <OutlinedButton
              label={'Add Assessments Results'}
              showArrowIcon={false}
              onClick={() => navigate(PATHS.PROFILE_ADD_ASSESSMENT)}
            />
          </div>
        </>
      );
    }
  };

  return isLoading?.global ? (
    <Card classNames="p-4 mt-4 h-[calc(100vh-275px)]">
      <div className="h-full w-full flex justify-center items-center">
        <SpinningLoader width="w-8" height="h-8" />
      </div>
    </Card>
  ) : !aboutMyself &&
    location?.current &&
    location?.raised &&
    location?.born &&
    softSkillsData.length !== 0 &&
    !genderValue &&
    !pronounValue &&
    languagesData.length !== 0 &&
    assessmentBigfiveData.length !== 0 &&
    assessmmentAttributesData.length !== 0 &&
    assessmmentMyresData &&
    !assessmmentHollandData &&
    !assessmmentEnneagramData &&
    !assessmmentDiscData &&
    otherUserId ? (
    <Card classNames="p-4 mt-4 h-[calc(100vh-275px)] flex flex-col justify-center item-center m-auto text-center">
      <img src={noWork} alt="noWork" className="w-[20%] md:w-[10%] mx-auto " />
      <h4 className="font-semibold text-greydark text-[12px] md:text-[14px] my-2">
        No About Self added yet.
      </h4>
    </Card>
  ) : (
    <Card classNames="mt-4">
      {renderAboutMyself()}
      {renderMoreAboutYou()}
      {renderMyselfLocation()}
      {renderAssesments()}

      {!otherUserId ? (
        <>
          <Modal
            isOpen={isOpenAboutMyselfModal}
            onClose={() => setIsOpenAboutMyselfModal(false)}
            isTitle={true}
            title={`${aboutMyself ? 'Update' : 'Add'} About Me`}
            childrenClassNames="overflow-y-auto"
            padding="p-0"
            titleClassNames=""
            // width="md:w-[70vw] w-[90vw]"
            titleParentClassNames="md:m-3 m-0"
            height=" max-h-[100dvh] md:h-auto"
          >
            <AddAboutMe
              hideHeadingAndSubHeading={true}
              parentWrapClass=" flex-col mt-[0] mb-[0] items-stretch mx-[20px]"
              buttonClass="mx-[20px]"
              headingClass="font-semibold"
              setIsSaveClicked={setIsSaveClicked}
              getMyselfData={getMyselfData}
            />
          </Modal>

          <Modal
            isTitle={true}
            title={'Add About You'}
            isOpen={isMoreAboutYouOpen}
            onClose={() => setIsMoreAboutYouOpen(false)}
            width="md:w-[50vw] w-[90vw]"
            padding={0}
            titleClassNames="pl-0"
          >
            <>
              <MoreAboutYou
                hideLocationHeading={true}
                childClassPadding="py-[9px]"
                parentWrapClass="shadow-none"
                setIsSaveClicked={setIsSaveClicked}
                getMyselfData={getMyselfData}
              />
            </>
          </Modal>

          <Modal
            isTitle={true}
            title={'Add Location'}
            isOpen={isLocationModalOpen}
            onClose={() => setIsLocationModalOpen(false)}
            width="md:w-[80vw] w-[90vw]"
            padding={0}
            titleClassNames="pl-0"
          >
            <>
              <Location
                parentWrapClass="shadow-none"
                hideLocationHeading={true}
                childClassPadding="py-[0px] pb-[32px]"
                setIsSaveClicked={setIsSaveClicked}
                getMyselfData={getMyselfData}
              />
            </>
          </Modal>
        </>
      ) : (
        ''
      )}
    </Card>
  );
}

export default Myself;
